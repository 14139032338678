import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@pokemon/design-v2.button'

export function InviteSuccessPage () {
  const { t } = useTranslation()

  return (
    <>
      <div className='success-page-main centered-text'>
        <h2>{t('email_sent', 'Email Sent!')}</h2>
        <p>{t('child_email_sent_message', 'Be sure to let your child know about the email so they can create a Pokémon Trainer Account on their own.')}</p>
      </div>
      <Button data-testid="parent-profile-button-test" text={t('go_back_button_label', 'Go Back to your Account')} buttonStatus='secondary' variant='text' href='/profile' />
    </>
  )
}
