import { AppInfo } from '../../utils/utils'
import { ToggleSwitch } from '@pokemon/design.ui.input.toggle-switch'
import './generic_app_game_page.css'

interface AppGameHeaderProps {
  appInfo: AppInfo
  handleOnClick?: () => void
  switchState: boolean
  disabled: boolean
}

export function AppGameHeader (props: AppGameHeaderProps) {
  const { appInfo, switchState, disabled, handleOnClick } = props
  const id = `${appInfo.client_id}-toggle`

  return (
    <div className='app-game-header'>
      <div className='app-game-title'>
        <img className='app-game-logo' src={appInfo.client_logo ? appInfo.client_logo : 'https://static.pokemon.com/images/default_pokeball_logo.png'} alt="" />
        <h1>{appInfo.client_name}</h1>
      </div>
      <div>
        <ToggleSwitch id={id} key={id} checked={switchState} disabled={disabled} controlled={true} onClick={handleOnClick} />
      </div>
    </div>
  )
}
