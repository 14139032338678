import { Form, Field } from 'react-final-form'
import * as EmailValidator from 'email-validator'
import React, { useContext, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { getSupportLink } from '@tpci/i18next-ext'
import { checkField, sendChildEmail } from '../../utils/tamApi'
import { Button } from '@pokemon/design-v2.button'
import { TextField } from '@pokemon/design.ui.input.text-field'
import { LoadingContext } from '@pokemon/design.ui.loading-spinner'
import { screens } from './child_acc_creation'
import { AuthContextValue, AuthContext } from '../../utils/auth/context'

interface ChildAdultProps {
  changeScreen?: (screen: string) => void
  setError: () => void
}
interface FormValues {
  email?: string
}

export const ChildAdult = (props: ChildAdultProps) => {
  const { t, i18n } = useTranslation()
  const { changeScreen } = props
  const { setLoading } = useContext(LoadingContext)
  const [errorEmailValidation, setErrorEmailValidation] = useState(false)
  const { accessToken } = useContext<AuthContextValue>(AuthContext)
  const supportLink = getSupportLink(i18n.language)

  const validateEmail = (t: (key: string, fallback: string) => string) => (value: string) => {
    if (!EmailValidator.validate(value)) {
      return t('error_invalid_email', 'Please enter a valid email address')
    }
  }

  const handleFocusEmail = () => {
    if (errorEmailValidation) {
      setErrorEmailValidation(false)
    }
  }

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true)
    if (accessToken) {
      const childsEmail = formValues.email
      try {
        if (accessToken && childsEmail) {
          const emailCheck = await checkField(accessToken.token, { email: childsEmail })
          if (emailCheck.data.email) {
            setErrorEmailValidation(true)
          } else {
            await sendChildEmail(accessToken.token, childsEmail)
            if (changeScreen) {
              changeScreen(screens.CHILD_INVITE)
            }
          }
        }
      } catch (e) {
        console.log(e)
        props.setError()
      }
    }
    setLoading(false)
  }

  return <Form
    onSubmit={onSubmit}
    render={
      ({ handleSubmit, submitting, valid }) => (
        <form className='centered-text' onSubmit={handleSubmit}>
          <h1>{t('child_adult_title', 'Your Child Can Create Their Own Account!')}</h1>
          <p className="medium_size_text">{t('child_adult_info', 'Enter your child\'s email. and a link will be sent so they can create their own Pokémon Trainer Club account.')}</p>
          {
            <>
              <Field
                name="email"
                validate={validateEmail(t)}
              >
                {({ input, meta }) => (
                  <>
                    <TextField
                      placeholder={t('child_invite_email_placeholder', 'Child\'s Email')}
                      errorText={meta.error && meta.touched && <span className='input-error'>{meta.error}</span>}
                      {...input}
                      onFocus={handleFocusEmail}
                    />
                  </>
                )}
              </Field>
              {errorEmailValidation &&
                <div className='error-email-validation'>
                  <Trans t={t} i18nKey='email_validation_error_w_link'
                    defaults='There is already an account with this email, or the email is not valid. If you think this is a mistake, <customerServiceLink>contact customer service</customerServiceLink>'
                    components={{ customerServiceLink: <a href={supportLink} className='error-email-validation-support'></a> }} />
                </div>}
            </>
          }
          <Button
            text={t('invite_button', 'Send Invitation')}
            data-testid="email-submit-button"
            type="submit"
            disabled={submitting || !valid}
          />
        </form>
      )
    }
  />
}
