import './error_custom_body.css'
import { ErrorPageProps } from './error_page'

export function ErrorCustomBody (props: ErrorPageProps) {
  const { message, title } = props

  return (
    <div className='main-wrapper'>
      {title && <h1 className='error-title'>{title}</h1>}
      {message && <p className='error-message'>{message}</p>}
      <img className='error-image' src="https://static.pokemon.com/images/pikachu_img.svg" alt="" />
    </div>
  )
}
