import React, { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import './index.css'
import App from './app'
import spinner from './assets/images/pokeball_gray.png'
import './utils/i18n'
import reportWebVitals from './utils/reportWebVitals'
import { AuthContextProvider } from './utils/auth/context'
import { LoadingSpinner } from '@pokemon/design.ui.loading-spinner'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import config from './utils/config'
import { SideBarMenuContextProvider } from '@pokemon/design.ui.menu.sidebar-menu'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { ThemeProvider, defaultTheme } from '@pokemon/design-v2.themes.mui-theme'

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.REACT_APP_LAUNCHDARKLY_CLIENT_ID
  })
  ReactDOM.render(
    <LDProvider>
      <AuthContextProvider>
        <SideBarMenuContextProvider>
          <ThemeProvider theme={defaultTheme}>
            <Router>
              <StrictMode>
                <Suspense fallback={<img className='spinner' src={spinner} alt='…' />}>
                  <LoadingSpinner altText='...'>
                    <App />
                  </LoadingSpinner>
                </Suspense>
              </StrictMode>
            </Router>
          </ThemeProvider>
        </SideBarMenuContextProvider>
      </AuthContextProvider>
    </LDProvider>,
    document.getElementById('root')
  )
})()

datadogRum.init({
  applicationId: 'ebae9453-7a42-4be7-8146-7285a4e8c2ff',
  clientToken: 'puba6e837244c19216be77e143d83c681c9',
  site: 'datadoghq.com',
  service: 'trainer-account-management',
  env: process.env.REACT_APP_STAGE,
  version: require('../package.json').version,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [
    { match: config.ACAPI_BASE_URL, propagatorTypes: ['datadog'] },
    { match: config.AAAPI_BASE_URL, propagatorTypes: ['datadog'] },
    { match: config.TAMAPI_BASE_URL, propagatorTypes: ['datadog'] }
    // will add URL once Oryhydra and UPAPI is integration with DataDog
    // `https://access.${process.env.REACT_APP_STAGE}.pokemon.com`,
    // (url) => url.startsWith(`https://me.${process.env.REACT_APP_STAGE}.pokemon.com`),
  ]
})

datadogLogs.init({
  clientToken: 'puba6e837244c19216be77e143d83c681c9',
  site: 'datadoghq.com',
  silentMultipleInit: true,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100
})

datadogRum.startSessionReplayRecording()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
