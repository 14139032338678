import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import logo from './assets/images/pokemon_logo.png'

export function Header () {
  const { t } = useTranslation()
  return (
    <div>
      <header>
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <div className='logo-wrapper'>
          <img className='logo' src={logo} alt={t('pokemon_logo', 'The Pokémon logo')} />
        </div>
      </header>
    </div>
  )
}

interface AppWrapperProps {
  children: React.ReactNode
}

export function AppWrapper ({ children }: AppWrapperProps) {
  return (
    <div className='body-container'>
        { children }
    </div>
  )
}

export const Footer = () => {
  const { t } = useTranslation()
  const currYear = new Date().getFullYear()

  return (
    <div>
      <footer className='footer-wrapper'>
        <span className='copyright'>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
            <span>
                <Trans
                t={t}
                i18nKey='copyright'
                defaults='©{{curr_year}} Pokémon.'
                values={{ curr_year: currYear }}
              />
            </span>
            <span>
                <Trans
                t={t}
                i18nKey='copyright_creatures'
                defaults='©1995 - {{curr_year}} Nintendo/Creatures Inc./GAME FREAK inc. TM, ®Nintendo.'
                values={{ curr_year: currYear }}
              />
            </span>
          </div>
        </span>
      </footer>
    </div>
  )
}
