import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentBox } from '@pokemon/design.ui.containers.content-box'
import { LoadingContainer, LoadingContext } from '@pokemon/design.ui.loading-spinner'
import { ChildDOB } from './child_dob'
import { ChildEmail } from './child_email'
import { ChildAdult } from './child_adult'
import { InviteSuccessPage } from './child_invite_page'
import { SuccessPage } from './success_page'
import './child_acc_creation.css'
import { CreateScreenNamePage } from '@pokemon/ptc-user.create-screen-name-page'
import { AuthContextValue, AuthContext } from '../../utils/auth/context'
import { checkScreenname, checkUsername } from '../../utils/userCreationAPI'
import { createChildAccount, createChildAccountOld, retrieveAccounts } from '../../utils/tamApi'
import { countryCodeReplacementString, getTwoCharacterCountryCodeFromLanguage } from '@tpci/i18next-ext'
import i18n from '../../utils/i18n'
import { DocumentPage, DocumentType } from '@pokemon/ptc-user.document-page'
import { StepIndicatorProps } from '@pokemon/design.ui.step-indicator'
import { useNavigate } from 'react-router'
import { Modal } from '@pokemon/design.ui.containers.modal'
import { ModalContext } from '@pokemon/design.ui.containers.base-modal'
import { CreateUsernamePasswordPage } from '@pokemon/ptc-user.create-username-password-page'
import { CreateUsernamePasswordPage as OldCreateUsernamePasswordPage } from 'old-user-password-page'
import { ChildAccountData, findAccountInAccountsArray } from '../../utils/utils'
import { AccountPickerAccount } from '@pokemon/design.ui.menu.account-picker'
import config from '../../utils/config'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const screens: Readonly<{
  DATE_OF_BIRTH: 'start',
  ENTER_EMAIL: 'child email',
  CHILD_ADULT: 'child adult',
  CHILD_INVITE: 'child invite',
  SCREEN_NAME: 'child screen name',
  LOGIN_INFO: 'child login info',
  TERMS_OF_USE: 'terms of use',
  PRIVACY_NOTICE: 'privacy notice',
  SUCCESS: 'success'
}> = Object.freeze({
  DATE_OF_BIRTH: 'start',
  ENTER_EMAIL: 'child email',
  CHILD_ADULT: 'child adult',
  CHILD_INVITE: 'child invite',
  SCREEN_NAME: 'child screen name',
  LOGIN_INFO: 'child login info',
  TERMS_OF_USE: 'terms of use',
  PRIVACY_NOTICE: 'privacy notice',
  SUCCESS: 'success'
})

interface ChildAccCreationProps {
  setError: (text?: string) => void
  switchChildAcc: (account: AccountPickerAccount) => void
}

const closeModalName = 'confirmChildCreationExit'

export function ChildAccCreation (props: ChildAccCreationProps) {
  const { setError, switchChildAcc } = props
  const { t } = useTranslation()
  const { ptc8791UpdatedUsernamePasswordPage } = useFlags()
  const initialScreenStack: string[] = ['start']
  const [screenStack, setScreenStack] = useState(initialScreenStack)
  const [activeStep, setActivestep] = useState<number>()
  const [createdWithParentEmail, setCreatedWithParentEmail] = useState<boolean>()
  const [currentScreen, setCurrentScreen] = useState<string>(screens.DATE_OF_BIRTH)
  const [childData, setChildData] = useState<ChildAccountData>()
  const { accessToken, setAccounts, accounts, loggedUserProfile } = useContext<AuthContextValue>(AuthContext)
  const { setLoading, isLoading } = useContext(LoadingContext)

  const navigate = useNavigate()

  const { openModal, closeModal } = useContext(ModalContext)

  /* use country to determine which links to pass to documentforms. In terms of content,
  we have two sets: the USA version, and the international version translated into various
  languages. So, for the US, we use the US version regardless of language, and for
  international audiences we use the closest language version regardless of country.
   */
  const countryCode = loggedUserProfile?.country_code ?? ''
  let termsCode
  if (countryCode === 'US') {
    termsCode = 'us'
  } else {
    termsCode = getTwoCharacterCountryCodeFromLanguage(i18n.language).toLowerCase()
    if (termsCode === 'us') termsCode = 'uk'
  }

  const saveDoB = (dateOfBirth: string, screen: string) => {
    setChildData(prevState => ({
      ...prevState,
      date_of_birth: dateOfBirth
    }))
    changeScreen(screen)
  }

  const saveScreenname = (screenname: string | undefined) => {
    setChildData(prevState => ({
      ...prevState,
      screen_name: screenname
    }))
    changeScreen(screens.LOGIN_INFO)
  }

  useEffect(() => {
    setActivestep(screenStack.length - 1)
  }, [screenStack.length])

  const changeScreen = (screen: string) => {
    pushToScreenStack(screen)
    setCurrentScreen(screen)
  }

  const saveEmail = (email: string, useParentEmail: boolean) => {
    setChildData(prevState => ({
      ...prevState,
      email: email
    }))
    setCreatedWithParentEmail(useParentEmail)
    changeScreen(screens.TERMS_OF_USE)
  }

  const pushToScreenStack = (newScreen: string) => {
    setScreenStack(prevStack => [...prevStack, newScreen])
  }

  const changeToChildAcc = () => {
    const childAccInfo = findAccountInAccountsArray(accounts!, childData?.username!)
    if (childAccInfo) {
      switchChildAcc(childAccInfo)
      navigate('/profile')
    } else {
      console.error('created child account does not exist on parent accounts')
      setError()
    }
  }

  const handleBackButton = () => {
    setScreenStack(screenStack.slice(0, screenStack.length - 1))
    setCurrentScreen(screenStack.at(-2)!)
  }

  let innerBody, onBack
  let showBack = false

  switch (currentScreen) {
    case screens.DATE_OF_BIRTH:
      innerBody = <ChildDOB setError={setError} saveDoB={saveDoB} changeScreen={changeScreen} />
      break
    case screens.ENTER_EMAIL:
      innerBody = <ChildEmail setError={setError} saveEmail={saveEmail} />
      showBack = true
      break
    case screens.CHILD_ADULT:
      innerBody = <ChildAdult setError={setError} changeScreen={changeScreen} />
      break
    case screens.TERMS_OF_USE:
      innerBody = <DocumentPage documentType={DocumentType.TermsOfUse}
        documentURL={config.TERMS_URL.replaceAll(countryCodeReplacementString, termsCode)}
        setError={(error) => {
          console.error(error)
          setError(t('error_terms_of_use', 'An unexpected error occurred while getting the Terms of Use, please try again later.'))
        }}
        onAccept={() => {
          setChildData(prevState => ({
            ...prevState,
            terms: true
          }))
          changeScreen(screens.PRIVACY_NOTICE)
        }
        }
        onCancel={() => openModal(closeModalName)
        }
      />
      showBack = true
      break
    case screens.PRIVACY_NOTICE:
      innerBody = <DocumentPage documentType={DocumentType.PrivacyNotice}
        documentURL={config.PRIVACY_POLICY_URL.replaceAll(countryCodeReplacementString, termsCode)}
        setError={() => setError(t('error_privacy_notice', 'An unexpected error occurred while getting the Privacy Notice, please try again later.'))}
        onAccept={() => {
          setChildData(prevState => ({
            ...prevState,
            privacy_notice: true
          }))
          if (countryCode.toLowerCase() === 'jp') {
            changeScreen(screens.LOGIN_INFO)
          } else {
            changeScreen(screens.SCREEN_NAME)
          }
        }}
        onCancel={() => openModal(closeModalName)}
      />
      showBack = true
      break
    case screens.CHILD_INVITE:
      innerBody = <InviteSuccessPage />
      break
    case screens.SCREEN_NAME:
      innerBody = <CreateScreenNamePage isChild={true}
        checkScreenname={(screenname: string) => checkScreenname(screenname, accessToken?.token)}
        onSkip={() => saveScreenname(undefined)}
        onScreennameCleared={(screenname) => saveScreenname(screenname)}
        setError={setError} />
      showBack = true
      break
    case screens.LOGIN_INFO:
      if (ptc8791UpdatedUsernamePasswordPage) {
        innerBody = <CreateUsernamePasswordPage isChild={true}
        attemptUserCreation={async (username:string, password:string) => {
          const newChildData = { ...childData, username, password }
          setLoading(true)
          try {
            const result = await createChildAccount(accessToken?.token!, newChildData)
            if (!result || !result?.data) {
              setError()
            }
            if (!result.data.errors) {
              const resp = await retrieveAccounts(accessToken?.token!)
              if (resp.data && resp.data.accounts && resp.data.accounts.length > 0) {
                const accounts = resp.data.accounts
                setAccounts(accounts)
                changeScreen(screens.SUCCESS)
              } else {
                console.error('error retrieving data after account created')
                setError()
              }
            }
            setLoading(false)
            return Promise.resolve(result.data.errors)
          } catch (e) {
            console.error(e)
            setLoading(false)
            setError()
          }
        }
        }
        screenname={childData?.screen_name}/>
      } else {
        innerBody = <OldCreateUsernamePasswordPage isChild={true}
        screenname={childData?.screen_name}
        checkUsername={(username: string) => checkUsername(username, accessToken?.token)}
        onClearedLoginInfo={(values: any) => {
          setLoading(true);
          (async () => {
            try {
              await createChildAccountOld(accessToken?.token!, { ...childData, ...values })
              const resp = await retrieveAccounts(accessToken?.token!)
              if (resp.data && resp.data.accounts && resp.data.accounts.length > 0) {
                const accounts = resp.data.accounts
                setAccounts(accounts)
                changeScreen(screens.SUCCESS)
              } else {
                console.error('data is empty or there are no accounts from retrieveAccounts')
                setError()
              }
            } catch (e) {
              console.log(e)
              setError()
            }
            setLoading(false)
          })()
        }}
        setError={setError} />
      }

      showBack = true
      break
  }

  if (currentScreen === screens.SUCCESS) {
    return (
      <ContentBox autoSizeBox={true}>
        <SuccessPage automaticallyActivated={createdWithParentEmail} changeToChildAcc={changeToChildAcc} />
      </ContentBox>
    )
  } else {
    let stepProps: StepIndicatorProps = { steps: 0 }

    if (currentScreen !== screens.CHILD_ADULT && currentScreen !== screens.CHILD_INVITE) {
      stepProps = { steps: 6, currentStep: activeStep }
    }

    if (showBack && !isLoading) {
      onBack = handleBackButton
    } else {
      onBack = undefined
    }

    return (
      <ContentBox className='child-account-creation-content' onBack={onBack} stepIndicatorProps={stepProps} autoSizeBox={true} onClose={() => openModal(closeModalName)}>
        <Modal
          name={closeModalName}
          primaryOnClick={() => {
            navigate(-1)
            closeModal(closeModalName)
          }}
          title={t('close_user_creation_modal_title', 'Back to Account Manager?')}
          primaryText={t('close_user_creation_modal_button', 'Go Back to Account Manager')}
          closeText={t('close_user_creation_modal_cancel', 'Stay on This Page')}
          contentBoxProps={{
            Logo: null
          }}
        >
          <p className='modal-contents'>{t('close_user_creation_modal_description', 'Any unsaved changes will be discarded')}</p>
        </Modal>
        <LoadingContainer altText={t('loading_spinner', 'Loading...')}>
          {innerBody}
        </LoadingContainer>
      </ContentBox>
    )
  }
}
