import { useTranslation } from 'react-i18next'
import { BaseContentBox } from '@pokemon/design.ui.containers.base-content-box'
import { ErrorFallback } from '@pokemon/design.ui.error.error-fallback'
import { ErrorCustomBody } from './error_custom_body'

export function ErrorPageNotFound () {
  const { t } = useTranslation()

  return (
    <BaseContentBox >
        <ErrorFallback
            customBody={<ErrorCustomBody message={"Sorry, Trainer! We're not able to find the page you're looking for."}/>}
            primaryButton={{
              text: t('primary_button_not_found_page', 'Contact Pokémon Support'),
              url: 'https://support.pokemon.com'
            }}
            secondaryButton={{
              text: t('secondary_button_not_found_page', 'Return to Dashboard'),
              url: '/home'
            }}
        />
    </BaseContentBox>
  )
}
