import { ReactNode, useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AuthContextValue, AuthContext } from '../../utils/auth/context'
import { BaseContentBox } from '@pokemon/design.ui.containers.base-content-box'
import { UnorderedList } from '@pokemon/design.ui.containers.unordered-list'
import { AppInfo, findAppOnArray } from '../../utils/utils'
import { Modal } from '@pokemon/design.ui.containers.modal'
import { ModalContext } from '@pokemon/design.ui.containers.base-modal'
import { AppGameHeader } from './app_game_header'
import { revokePermissions, grantPermissions } from '../../utils/tamApi'
import { Callout } from '@pokemon/design.ui.containers.callout'
import { Navigate, useParams } from 'react-router-dom'
import './generic_app_game_page.css'
import { Spinner } from '@pokemon/design.ui.loading-spinner'
import { PokemonGoGamePage } from '../pokemon_go_game_page/pokemon_go_game_page'
import { underageScopeOptions, overageScopeOptions } from '@pokemon/ptc-user.consent-scope-list/consent_localizations'

interface GenericAppGamePageProps {
  setError: (text?: string) => void
}

export function GenericAppGamePage (props: GenericAppGamePageProps) {
  const { setError } = props
  const { isChildView, accessToken, selectedAccount, appList, setAppList } = useContext<AuthContextValue>(AuthContext)
  const { t } = useTranslation()
  const { appId } = useParams()
  const { openModal, closeModal } = useContext(ModalContext)

  const underageScopes = underageScopeOptions()
  const overageScopes = overageScopeOptions()

  let currentApp: AppInfo
  let switchState: boolean

  if (!appList || !appList.length) {
    return <Spinner altText={t('pokemon_spinner', 'Loading')} />
  }

  const app = findAppOnArray(appList!, appId!)

  if (!app) {
    return <Navigate to={`/app/${appList[0].client_id}`} />
  } else {
    currentApp = app
    if (app && app.unconsented_scopes.length === 0) {
      switchState = (true)
    } else {
      switchState = (false)
    }
  }

  const appGameMainMsg = isChildView ? t('app_game_main_message_child', 'This app would have access to the following from your child’s Pokémon Trainer Club account:') : t('app_game_main_message', 'This app would have access to the following from your Pokémon Trainer Club account:')
  const missingFields = currentApp.missing_fields
  const showCallout = missingFields.length > 0
  const childGuid = isChildView ? selectedAccount?.guid : ''

  const scopeInfo = (isChildView ? underageScopes : overageScopes) as {
    [scope: string]: {
      title: string,
      details: string[]
    }
  }

  const getScopesToDisplay = () => {
    return currentApp.scopes.map((scope, index) => {
      if (scope in scopeInfo) {
        return (
          <UnorderedList key={index} header={scopeInfo[scope].title} text={scopeInfo[scope].details}/>
        )
      } else {
        return null
      }
    })
  }

  const getMissingFields = (missingFields: string[]): string => {
    return missingFields.map((field) => scopeInfo[field]?.title).filter(item => item !== null).join(', ')
  }

  const handleToggleSwitch = () => {
    if (switchState) {
      openModal('revokeAccessModal')
    } else {
      openModal('grantAccessModal')
    }
  }

  const updateAppList = (type: string) => {
    const updatedAppList = appList!.map(app => {
      if (app.client_id === currentApp.client_id) {
        if (type === 'revoke') {
          return {
            ...app,
            unconsented_scopes: app.scopes,
            consented_scopes: []
          }
        } else if (type === 'grant') {
          return {
            ...app,
            consented_scopes: app.scopes,
            unconsented_scopes: []
          }
        }
      }
      return app
    })
    setAppList(updatedAppList)
  }

  const handleRevokeAccess = async () => {
    try {
      await revokePermissions(accessToken!.token, currentApp.client_id, childGuid)
      updateAppList('revoke')
      closeModal('revokeAccessModal')
    } catch (error: any) {
      setError()
    }
  }

  const handleGrantAccess = async () => {
    try {
      await grantPermissions(accessToken!?.token, currentApp.client_id, { scopes: currentApp.scopes }, childGuid)
      updateAppList('grant')
      closeModal('grantAccessModal')
    } catch (error: any) {
      setError()
    }
  }

  return (
    <>
      <BaseContentBox customWidth={700} autoSizeBox={true} title={<AppGameHeader key={currentApp.client_id} appInfo={currentApp} switchState={switchState} disabled={showCallout && !switchState} handleOnClick={handleToggleSwitch} />}>
        {showCallout && <Callout variant='error'>
          <p>
            {t('consent_missing_fields', 'In order to consent to this app, you must provide the following information: ')}
          </p>
          <p>
            {getMissingFields(currentApp.missing_fields)}
          </p>
        </Callout>
        }
        <p>{appGameMainMsg}</p>
        {getScopesToDisplay()}
      </BaseContentBox>
      {currentApp.client_id === 'pokemon-go' && <PokemonGoGamePage setError={setError} />}
      <RevokeAccessModal logo={currentApp.client_logo ? currentApp.client_logo : 'https://static.pokemon.com/images/default_pokeball_logo.png'} clientName={currentApp.client_name} isChildView={isChildView} handleRevokeAccess={handleRevokeAccess} />
      <GrantAccessModal logo={currentApp.client_logo ? currentApp.client_logo : 'https://static.pokemon.com/images/default_pokeball_logo.png'} scopes={getScopesToDisplay()} isChildView={isChildView} handleGrantAccess={handleGrantAccess} clientName={currentApp.client_name} />
    </>
  )
}

interface RevokeAccessModalProps {
  logo: string
  clientName: string
  isChildView: boolean
  handleRevokeAccess: () => void
}

const RevokeAccessModal = (props: RevokeAccessModalProps) => {
  const { logo, clientName, isChildView, handleRevokeAccess } = props
  const { t } = useTranslation()

  return <Modal
    name='revokeAccessModal'
    primaryOnClick={() => {
      handleRevokeAccess()
    }}
    title={t('revoke_access_title', 'Revoke Access')}
    primaryText={t('confirm', 'Confirm')}
    closeText={t('cancel', 'Cancel')}
    contentBoxProps={{
      Logo: () => {
        return <img src={logo} style={{ width: '80px', height: '80px' }} alt='' />
      }
    }}
  >
    <div className='modal-contents'>
      {isChildView ? <Trans t={t} i18nKey='revoke_access_detail_child' defaults='You are removing {{client_name}} access to your child’s Pokémon Trainer Club account.' values={{ client_name: clientName }} /> : <Trans t={t} i18nKey='revoke_access_detail' defaults='You are removing {{client_name}} access to your Pokémon Trainer Club account.' values={{ client_name: clientName }} />}
      <p>
        {t('revoke_access_question', 'Are you sure?')}
      </p>
      {isChildView ? <Trans t={t} i18nKey='revoke_access_detail_log_in_child' defaults='Your child will no longer be able to log in {{client_name}} with their Pokémon Trainer Club account and may lose any progress in the app.' values={{ client_name: clientName }} /> : <Trans t={t} i18nKey='revoke_access_detail_log_in' defaults='You will no longer be able to log in {{client_name}} with your Pokémon Trainer Club account and may lose any progress in the app.' values={{ client_name: clientName }} />}
      <p>
        {t('revoke_access_confirm', 'Click “Confirm” to proceed.')}
      </p>
    </div>
  </Modal>
}

interface GrantAccessModalProps {
  logo: string
  clientName: string
  scopes: ReactNode[]
  isChildView: boolean
  handleGrantAccess: () => void
}

const GrantAccessModal = (props: GrantAccessModalProps) => {
  const { logo, clientName, scopes, isChildView, handleGrantAccess } = props
  const { t } = useTranslation()

  return <Modal
    name='grantAccessModal'
    primaryOnClick={() => {
      handleGrantAccess()
    }}
    title={t('grant_access_modal_title', 'Grant Access')}
    primaryText={t('confirm', 'Confirm')}
    closeText={t('cancel', 'Cancel')}
    contentBoxProps={{
      Logo: () => {
        return <img src={logo} style={{ width: '80px', height: '80px' }} alt='' />
      }
    }}
  >
    <div className='modal-contents'>
      <p>
        {isChildView ? t('grant_access_request_child', 'This app is requesting access to your child’s Pokémon Trainer Club account.') : t('grant_access_request', 'This app is requesting access to your Pokémon Trainer Club account.')}
      </p>
      <p>{t('grant_access_detail', 'This app will have access to the following:')}</p>
      <div className='scopes-display' data-testid='scopes-display-modal'>
        {scopes}
      </div>
      <p>
        {isChildView ? <Trans t={t} i18nKey='grant_access_question_child' defaults='Do you wish to allow {{client_name}} access to your child’s Pokémon Trainer Club account?' values={{ client_name: clientName }} /> : <Trans t={t} i18nKey='grant_access_question' defaults='Do you wish to allow {{client_name}} access to your Pokémon Trainer Club account?' values={{ client_name: clientName }} />}
      </p>
    </div>
  </Modal>
}
