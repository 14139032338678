import { useContext, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchAccessTokenFromCallback } from '../../utils/auth/oauth'
import { useTranslation } from 'react-i18next'
import { Spinner } from '@pokemon/design.ui.loading-spinner'
import { AuthContext, AuthContextValue } from '../../utils/auth/context'

export const Callback = ({ setError }: { setError: () => void }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { setAccessToken } = useContext<AuthContextValue>(AuthContext)

  const fetchAccessToken = async () => {
    const queryString = new URLSearchParams(location.search)
    const accessToken = await fetchAccessTokenFromCallback(queryString)

    setAccessToken(accessToken)

    navigate('/profile')
  }

  const needAccessToken = useRef(true)
  useEffect(() => {
    if (needAccessToken.current) {
      fetchAccessToken().catch((e) => {
        console.log(e)
        setError()
      })
      needAccessToken.current = false
    }
  })

  // This component will always redirect to another page
  return (
    <Spinner altText={t('pokemon_spinner', 'Loading')} />
  )
}

export default Callback
