import axios, { AxiosResponse } from 'axios'
import config from './config'
import { getRequestConfig } from './tamApi'

export interface MfaStatusPayload {
  /* eslint-disable camelcase */
  mfa_method: string[],
  /* eslint-enable camelcase */
}

export async function getMfaStatus (accessToken: string): Promise<AxiosResponse<MfaStatusPayload, any>> {
  await new Promise(resolve => setTimeout(resolve, 100))
  return axios.get<MfaStatusPayload>(`${config.MFAAPI_BASE_URL}/status`, {
    ...getRequestConfig(accessToken)
  })
}

export interface TotpSecretPayload {
  /* eslint-disable camelcase */
  totp_secret: string,
  qr_data: string,
  enrollment_ticket: string,
  /* eslint-enable camelcase */
}

export async function getTotpSecret (accessToken: string): Promise<AxiosResponse<TotpSecretPayload, any>> {
  await new Promise(resolve => setTimeout(resolve, 100))
  return axios.get<TotpSecretPayload>(`${config.MFAAPI_BASE_URL}/totp-secret`, {
    ...getRequestConfig(accessToken)
  })
}

export interface SetMfaPayload {
  /* eslint-disable camelcase */
  mfa_method: string
  enrollment_ticket: string
  totp: string
  /* eslint-enable camelcase */
}

export async function setMfa (accessToken: string, payload: SetMfaPayload): Promise<void> {
  return axios.post(`${config.MFAAPI_BASE_URL}/set`, payload, {
    ...getRequestConfig(accessToken)
  })
}

export interface DeleteMfaPayload {
  /* eslint-disable camelcase */
  mfa_method: string
  totp: string
  /* eslint-enable camelcase */
}

export async function deleteMfa (accessToken: string, payload: DeleteMfaPayload): Promise<void> {
  return axios.delete(`${config.MFAAPI_BASE_URL}/delete`, {
    data: payload,
    ...getRequestConfig(accessToken)
  })
}
