import { AccountPickerAccount } from '@pokemon/design.ui.menu.account-picker'
import React, { ReactNode, useState, createContext } from 'react'
import { AppInfo } from '../utils'

export type AuthContextProps = {
  // We will need to use childGuid in the future
  // childGuid?: string
  children: ReactNode
}

export type AccessToken = {
  token: string
  idToken: string
  expiry: Date
}

export type ProfileInfo = {
  /* eslint-disable camelcase */
  country_code: string
  date_of_birth: string
  email: string
  email_opt_in: boolean
  email_opt_in_pcenter: boolean
  first_name: string
  last_name: string
  public_profile: boolean
  screen_name: string
  username: string
  player_id: string
  /* eslint-enable camelcase */
}

export type AuthContextValue = {
  primaryUserAccount?: AccountPickerAccount
  setPrimaryUserAccount: React.Dispatch<React.SetStateAction<AccountPickerAccount | undefined>>
  primaryUserProfile?: ProfileInfo
  setPrimaryUserProfile: React.Dispatch<React.SetStateAction<ProfileInfo | undefined>>
  accounts?: AccountPickerAccount[]
  setAccounts: React.Dispatch<React.SetStateAction<AccountPickerAccount[] | undefined>>
  selectedAccount?: AccountPickerAccount
  setSelectedAccount: React.Dispatch<React.SetStateAction<AccountPickerAccount | undefined>>
  isChildView: boolean
  setIsChildView: React.Dispatch<React.SetStateAction<boolean>>
  loggedUserProfile?: ProfileInfo
  setLoggedUserProfile: React.Dispatch<React.SetStateAction<ProfileInfo | undefined>>;
  appList?: AppInfo[]
  setAppList: React.Dispatch<React.SetStateAction<AppInfo[] | undefined>>
  accessToken?: AccessToken
  setAccessToken: React.Dispatch<React.SetStateAction<AccessToken | undefined>>
};

export const AuthContext =
  createContext<AuthContextValue>({
    setPrimaryUserAccount: () => { },
    setPrimaryUserProfile: () => { },
    setAccounts: () => { },
    setSelectedAccount: () => { },
    setIsChildView: () => { },
    isChildView: false,
    setAccessToken: () => { },
    setLoggedUserProfile: () => { },
    setAppList: () => { }
  })

export function AuthContextProvider ({ children }: AuthContextProps) {
  const [primaryUserAccount, setPrimaryUserAccount] = useState<AccountPickerAccount | undefined>()
  const [primaryUserProfile, setPrimaryUserProfile] = useState<ProfileInfo | undefined>()
  const [accounts, setAccounts] = useState<AccountPickerAccount[] | undefined>()
  const [selectedAccount, setSelectedAccount] = useState<AccountPickerAccount | undefined>()
  const [isChildView, setIsChildView] = useState<boolean>(false)
  const [loggedUserProfile, setLoggedUserProfile] = useState<ProfileInfo | undefined>()
  const [appList, setAppList] = useState<AppInfo[] | undefined>()
  const [accessToken, setAccessToken] = useState<AccessToken | undefined>()

  return (
    <AuthContext.Provider
      value={{
        primaryUserAccount,
        setPrimaryUserAccount,
        primaryUserProfile,
        setPrimaryUserProfile,
        accounts,
        setAccounts,
        selectedAccount,
        setSelectedAccount,
        isChildView,
        setIsChildView,
        accessToken,
        setAccessToken,
        loggedUserProfile,
        setLoggedUserProfile,
        appList,
        setAppList
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
