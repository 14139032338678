import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from '@pokemon/design-v2.button'

interface SuccessPageProps {
  automaticallyActivated?: boolean
  changeToChildAcc: () => void
}

export function SuccessPage (props: SuccessPageProps) {
  const { automaticallyActivated, changeToChildAcc } = props
  const { t } = useTranslation()

  return (
    <>
      <div className='success-page-main'>
        <h2>{t('success_title', 'Success!')}</h2>
        <p>{t('success_message', 'Your child’s Pokémon Trainer Account has been created.')}</p>
        {!automaticallyActivated &&
          <Trans
            t={t}
            i18nKey='account_activation_message'
            defaults='Before your child can use their account, please log in with <strong>your child’s username and password</strong> to activate the account.'
            components={{ strong: <strong /> }}
          />}
      </div>
      <Button style={{ width: '100%' }} data-testid="parent-profile-button-test" text={t('go_back_button_label', 'Go Back to your Account')} variant='text' href='/profile' />
      {automaticallyActivated && <Button style={{ width: '100%' }} data-testid="manage-child-acc-button-test" text={t('manage_child_account_button_label', 'Manage Your Child’s Account')} variant='outlined' onClick={changeToChildAcc} />}
    </>
  )
}
