import axios, { AxiosRequestConfig } from 'axios'
import config from './config'

export interface ChangeProfilePayload {
  /* eslint-disable camelcase */
  first_name?: string,
  last_name?: string,
  screen_name?: string,
  email_opt_in?: boolean,
  email_opt_in_pcenter?: boolean,
  public_profile?: boolean
  /* eslint-enable camelcase */
}

export const getRequestConfig = (accessToken: string, withCredentials = true): AxiosRequestConfig => {
  return {
    headers: { Authorization: `Bearer ${accessToken}` },
    validateStatus: (code: number) => code === 200 || code === 201,
    withCredentials
  }
}

const getUrlWithChildGuid = (url: string, childGuid?: string, queryParams: string = '') => {
  const suffix = childGuid ? `/${childGuid}` : ''
  return `${url}${suffix}${queryParams}`
}

export const retrieveAccounts = (accessToken: string) => {
  return axios.get(`${config.TAMAPI_BASE_URL}/retrieve-user-info?fragment=accounts`, getRequestConfig(accessToken))
}

export const retrieveProfile = (accessToken: string, childGuid?: string) => {
  return axios.get(getUrlWithChildGuid(`${config.TAMAPI_BASE_URL}/retrieve-user-info`, childGuid, '?fragment=profile'), getRequestConfig(accessToken))
}

export const changeProfile = (accessToken: string, profileInfo: ChangeProfilePayload, childGuid?: string) => {
  return axios.post(getUrlWithChildGuid(`${config.TAMAPI_BASE_URL}/change-user-info`, childGuid), profileInfo, getRequestConfig(accessToken))
}

export const requestData = (accessToken: string, childGuid?: string) => {
  const req = getRequestConfig(accessToken)
  req.headers = { ...req.headers, Accept: 'application/pdf' }
  req.responseType = 'blob'
  return axios.get(getUrlWithChildGuid(`${config.TAMAPI_BASE_URL}/request-data`, childGuid), req)
}

export const changePassword = (accessToken: string, password: string, newPassword: string, childGuid?: string) => {
  return axios.post(getUrlWithChildGuid(`${config.TAMAPI_BASE_URL}/change-password`, childGuid), { password: password, new_password: newPassword }, getRequestConfig(accessToken))
}

export const checkField = (accessToken: string, fields: any) => {
  return axios.put(`${config.ACAPI_BASE_URL}/validate-user-fields`, fields, getRequestConfig(accessToken))
}

export const initiateDelete = (accessToken: string, childGuid?: string) => {
  return axios.post(getUrlWithChildGuid(`${config.TOKEN_BASE_URL}/initiate-delete`, childGuid), {}, getRequestConfig(accessToken, false))
}

export const sendChildEmail = (accessToken: string, email: string) => {
  return axios.post(`${config.ACAPI_BASE_URL}/send-child-email`, { child_email: email }, getRequestConfig(accessToken))
}

export const startActivationUser = (accessToken: string, username: string, email: string, password: string, childGuid?: string) => {
  return axios.post(getUrlWithChildGuid(`${config.ACAPI_BASE_URL}/start-activation`, childGuid), { username: username, pending_email: email, password: password }, getRequestConfig(accessToken))
}

export const activateUser = (accessToken: string, username: string, code: string, childGuid?: string) => {
  return axios.post(getUrlWithChildGuid(`${config.ACAPI_BASE_URL}/activate-user`, childGuid), { username: username, code: code }, getRequestConfig(accessToken))
}

export const retrieveAppList = (accessToken: string, childGuid?: string) => {
  return axios.get(getUrlWithChildGuid(`${config.AAAPI_BASE_URL}/app-list`, childGuid), getRequestConfig(accessToken, false))
}

export const grantPermissions = (accessToken: string, clientId:string, scopes: any, childGuid?: string) => {
  return axios.put(getUrlWithChildGuid(`${config.AAAPI_BASE_URL}/permissions/${clientId}`, childGuid), scopes, getRequestConfig(accessToken, false))
}

export const revokePermissions = (accessToken: string, clientId:string, childGuid?: string) => {
  return axios.delete(getUrlWithChildGuid(`${config.AAAPI_BASE_URL}/permissions/${clientId}`, childGuid), getRequestConfig(accessToken, false))
}

export const getChildGoSettings = (accessToken: string, childGuid?: string) => {
  return axios.get(getUrlWithChildGuid(`${config.AAAPI_BASE_URL}/go-settings`, childGuid), getRequestConfig(accessToken, false))
}

export const setChildGoSettings = (accessToken: string, goSettings: {}, childGuid?: string) => {
  return axios.put(getUrlWithChildGuid(`${config.AAAPI_BASE_URL}/go-settings`, childGuid), goSettings, getRequestConfig(accessToken, false))
}

export const createChildAccount = (accessToken: string, payload: any) => {
  return axios.post(`${config.ACAPI_BASE_URL}/create-user`, payload, {
    ...getRequestConfig(accessToken),
    validateStatus: (code: number) => code === 200 || code === 400
  })
}

export const createChildAccountOld = (accessToken: string, payload: any) => {
  return axios.post(`${config.ACAPI_BASE_URL}/create-user`, payload, getRequestConfig(accessToken))
}
