import React, { ReactNode, useContext } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { BaseContentBox } from '@pokemon/design.ui.containers.base-content-box'
import { Button } from '@pokemon/design-v2.button'
import { AuthContextValue, AuthContext } from '../../utils/auth/context'
import { initiateDelete, requestData } from '../../utils/tamApi'
import { Modal } from '@pokemon/design.ui.containers.modal'
import { ModalContext } from '@pokemon/design.ui.containers.base-modal'
import { LoadingContainer, LoadingContext } from '@pokemon/design.ui.loading-spinner'
import './privacy_page.css'

interface PrivacyPageProps {
  setError: (text?: string) => void
}

export function PrivacyPage (props: PrivacyPageProps) {
  const { setError } = props
  const { isChildView, selectedAccount, accessToken, primaryUserProfile } = useContext<AuthContextValue>(AuthContext)
  const parentEmail = primaryUserProfile?.email
  const { openModal } = useContext(ModalContext)
  const { setLoading } = useContext(LoadingContext)
  const { t } = useTranslation()
  const childGuid = isChildView ? selectedAccount?.guid : undefined

  const titleRequest = <div className='title'>{t('request_data', 'Request Data')}</div>
  const titleDelete = <div className='title'>{t('delete_account', 'Delete Account')}</div>

  const handleInitiateDelete = async () => {
    if (accessToken) {
      setLoading(true)
      try {
        const emailCheck = await initiateDelete(accessToken.token, childGuid)
        const answer = emailCheck.data.page_url
        if (answer) {
          openModal('confirmDeleteModal')
        }
      } catch (e) {
        console.log(e)
        setError()
      }
      setLoading(false)
    }
  }

  const handleRequestData = async () => {
    try {
      setLoading(true)
      const dataDoc = await requestData(accessToken!.token, childGuid)
      const blobUrl = window.URL.createObjectURL(dataDoc.data)
      const link = document.createElement('a')
      link.href = blobUrl
      link.setAttribute('download', t('request_data_filename', 'pokemon_trainer_club_info.pdf'))
      link.click()
      link.remove()
      URL.revokeObjectURL(blobUrl)
    } catch (e) {
      console.log(e)
      setError()
    } finally {
      setLoading(false)
    }
  }

  let requestDataMessage =
    <Trans
      t={t}
      i18nKey='request_data_text'
      defaults='Select this button to download your Pokémon Trainer Club profile data.' />
  let deleteAccountMessage =
    <Trans
      t={t}
      i18nKey='delete_account_text'
      defaults='When you request deletion of your Pokémon Trainer Club account, Pokémon will not be able to reactivate your account. You will no longer be able to access any of your previous account details. Additionally, you will no longer be able to use this account for Play! Pokémon, Pokémon GO, and details for these.<br/><br/>Select this button to begin the process of deleting your account.'
      components={{ br: <br /> }} />
  let confirmDeleteMessage =
    <Trans
      t={t}
      i18nKey='confirm_delete_details'
      defaults='An email was sent to <span style="color: blue;">{{parent_email}}</span> containing a link to delete your account.'
      values={{ parent_email: parentEmail }}
      components={{
        span: <span style={{ color: '#31A7D9' }} />
      }}
    />
  if (isChildView) {
    requestDataMessage =
      <Trans
        t={t}
        i18nKey='request_data_text_child'
        defaults='Select this button to download your child’s Pokémon Trainer Club profile data.' />
    deleteAccountMessage =
      <Trans
        t={t}
        i18nKey='delete_account_text_child'
        defaults='When you request deletion of your child’s Pokémon Trainer Club account, Pokémon will not be able to reactivate your child’s account. Your child will no longer be able to access any of their previous account details. Additionally, your child will no longer be able to use this account for Play! Pokémon, Pokémon GO, and details for these.<br/><br/>Select this button to begin the process of deleting your child’s account.'
        components={{ br: <br /> }} />
    confirmDeleteMessage =
      <Trans
        t={t}
        i18nKey='confirm_delete_details_child'
        defaults='An email was sent to <span style="color: blue;">{{parent_email}}</span> containing a link to delete your child’s account.'
        values={{ parent_email: parentEmail }}
        components={{
          span: <span style={{ color: '#31A7D9' }} />
        }}
      />
  }

  return (
    <LoadingContainer altText={t('loading_spinner', 'Loading...')}>
      <BaseContentBox customWidth={700} autoSizeBox={true} title={titleRequest}>
        <div className='privacy-formatting'>
          {requestDataMessage}
          <Button data-testid="request-data-button" text={t('request_data', 'Request Data')} variant='outlined' onClick={handleRequestData} />
        </div>
      </BaseContentBox>
      <BaseContentBox customWidth={700} autoSizeBox={true} title={titleDelete}>
        <div className='privacy-formatting'>
          {deleteAccountMessage}
          <Button data-testid="delete-account-button-test" text={t('delete_account', 'Delete Account')} buttonStatus='error' onClick={handleInitiateDelete} />
        </div>
      </BaseContentBox>
      <ConfirmDeleteModal confirmDeleteMessage={confirmDeleteMessage} />
    </LoadingContainer>
  )
}

interface ConfirmDeleteModalProps {
  confirmDeleteMessage: ReactNode
}

const ConfirmDeleteModal = (props: ConfirmDeleteModalProps) => {
  const { closeModal } = useContext(ModalContext)
  const { confirmDeleteMessage } = props
  const { t } = useTranslation()
  return <Modal
    name="confirmDeleteModal"
    primaryOnClick={() => {
      closeModal('confirmDeleteModal')
    }}
    title={t('confirm_delete_title', 'Check Your Email')}
    primaryText={t('confirm_delete_close', 'Close')}
    contentBoxProps={{
      Logo: null
    }}
  >
    <div className='modal-contents'>
      {confirmDeleteMessage}
    </div>
  </Modal>
}
