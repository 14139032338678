import { useTranslation } from 'react-i18next'
import { Spinner } from '@pokemon/design.ui.loading-spinner'
import { Outlet, useLocation } from 'react-router'
import { useEffect, useContext } from 'react'
import { kickoffLogin } from './oauth'
import { AccessToken, AuthContext, AuthContextValue } from './context'

export const AuthWrapper = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const auth = useContext<AuthContextValue>(AuthContext)

  const isValid = auth.accessToken ? auth.accessToken.expiry > new Date() : false

  const login = async () => {
    window.sessionStorage.clear()
    const queryString = new URLSearchParams(location.search)

    if (queryString?.get('lng')) {
      window.sessionStorage.setItem('lng', queryString.get('lng')!)
    }

    kickoffLogin()
  }

  useEffect(() => {
    if (!isValid) {
      // Try rehydrating token from session storage
      const sessionTokenJson = window.sessionStorage.getItem('oauth_access_token')
      if (sessionTokenJson) {
        const sessionToken = JSON.parse(sessionTokenJson) as AccessToken
        const sessionExpiry = new Date(sessionToken.expiry)

        // Check if saved token is still valid
        if (sessionExpiry > new Date()) {
          auth.setAccessToken({
            token: sessionToken.token,
            idToken: sessionToken.idToken,
            expiry: sessionExpiry
          })
          return
        }
      }

      // Login if we can't rehydrate a valid token
      login()
    } else {
      window.sessionStorage.setItem('oauth_access_token', JSON.stringify(auth.accessToken))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return isValid ? <Outlet /> : <Spinner altText={t('pokemon_spinner', 'Loading')} />
}

export default AuthWrapper
