import { AccountPickerAccount } from '@pokemon/design.ui.menu.account-picker'
import { MenuItemProps } from '@pokemon/design.ui.menu.sidebar-menu/menu-item'

export const HeaderLogo = (
  <img
    src={'https://static.pokemon.com/images/pokemon_logo.png'}
    alt={'Pokémon'}
    width={182}
    height={67}
  />
)

export function sortAppInfoArray (array: AppInfo[]): AppInfo[] {
  return array.sort((a: AppInfo, b: AppInfo) => {
    const nameA = a.client_name.toUpperCase()
    const nameB = b.client_name.toUpperCase()

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })
}

export function findAppOnArray (array: AppInfo[], id: string): AppInfo | null {
  for (let i = 0; i < array.length; i++) {
    if (array[i].client_id === id) {
      return array[i]
    }
  }
  return null
}

export function findAccountInAccountsArray (array: AccountPickerAccount[], username: string): AccountPickerAccount | null {
  for (const item of array) {
    if (item.username === username) {
      return item
    }
  }
  return null
}

/* eslint-disable camelcase */
export interface AppInfo {
  client_id: string
  client_name: string
  client_logo: string
  scopes: string[]
  consented_scopes: string[]
  unconsented_scopes: string[]
  missing_fields: string[]
}
export interface ChildAccountData {
  date_of_birth?: string
  email?: string
  screen_name?: string
  terms?: boolean
  privacy_notice?: boolean
  password?: string
  username?: string
}

export const generateAppGameLinks = (appList: AppInfo[]): MenuItemProps[] => {
  const links: MenuItemProps[] = appList.map(({ client_id, client_name }) => ({
    uniqueId: client_id,
    name: client_name,
    itemType: 'internalLink',
    to: `/app/${client_id}`
  }))
  return links
}
