import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BaseContentBox } from '@pokemon/design.ui.containers.base-content-box'
import { ProfileOverview } from '@pokemon/design.ui.containers.profile-overview'
import { Checkbox } from '@pokemon/design-v2.checkbox'
import { ToggleSwitch } from '@pokemon/design.ui.input.toggle-switch'
import { AuthContextValue, AuthContext } from '../../utils/auth/context'
import { LoadingContainer, LoadingContext, LoadingContextValue } from '@pokemon/design.ui.loading-spinner'
import { Button } from '@pokemon/design-v2.button'
import { TextField } from '@pokemon/design.ui.input.text-field'
import { Field, Form } from 'react-final-form'
import { FormApi } from 'final-form'

import './profile_overview_page.css'
import { ChangeProfilePayload, changeProfile } from '../../utils/tamApi'
import { Callout } from '@pokemon/design.ui.containers.callout'
import { getMessageFromScreennameErrors, validateScreenname } from '@pokemon/ptc-user.create-screen-name-page'

interface ProfileOverviewPageProps {
  setError: (text?: string) => void
  getLoggedUserProfile: (childGuid?: string) => void
}

export function ProfileOverviewPage (props: ProfileOverviewPageProps) {
  const { t } = useTranslation()
  const { loggedUserProfile, isChildView, accessToken, selectedAccount } = useContext<AuthContextValue>(AuthContext)
  const { setLoading } = useContext<LoadingContextValue>(LoadingContext)
  const [showSuccess, setShowSuccess] = useState(false)

  const name = useMemo(() => {
    if (loggedUserProfile?.first_name && loggedUserProfile?.last_name) {
      return `${loggedUserProfile?.first_name} ${loggedUserProfile?.last_name}`
    } else if (loggedUserProfile?.screen_name) {
      return loggedUserProfile?.screen_name
    } else if (loggedUserProfile?.username) {
      return loggedUserProfile.username
    }

    return ''
  }, [loggedUserProfile])

  const onSubmit = async (
    values: ChangeProfilePayload,
    form: FormApi<ChangeProfilePayload, Partial<ChangeProfilePayload>>,
    callback: any) => {
    setLoading(true)
    // construct payload based on what should be sent
    const payload: ChangeProfilePayload = {}

    if (!loggedUserProfile?.first_name && values.first_name) {
      payload.first_name = values.first_name
    }
    if (!loggedUserProfile?.last_name && values.last_name) {
      payload.last_name = values.last_name
    }
    if (!loggedUserProfile?.screen_name && values.screen_name) {
      payload.screen_name = values.screen_name
    }

    payload.public_profile = values.public_profile
    payload.email_opt_in = values.email_opt_in
    payload.email_opt_in_pcenter = values.email_opt_in_pcenter

    let childGuid
    if (isChildView) {
      childGuid = selectedAccount?.guid
    }

    try {
      await changeProfile(accessToken!.token, payload, childGuid)
      setShowSuccess(true)
      props.getLoggedUserProfile(childGuid)
      callback()
    } catch (error: any) {
      const screenNameErrors = error.response?.data.errors?.screen_name ?? []
      const message = getMessageFromScreennameErrors(t, screenNameErrors)
      if (message) {
        callback({ screen_name: message })
      } else {
        callback()
        props.setError()
      }
    } finally {
      setLoading(false)
    }
  }

  const validateScreennameIfPresent = function () {
    const validate = validateScreenname(t)
    return (screenname: string) => {
      if (screenname) {
        return validate(screenname)
      }
    }
  }

  return (
    <LoadingContainer altText={t('loading_spinner', 'Loading...')}>
      <ProfileOverview
        autoSizeBox={true}
        name={name}
        birthday={loggedUserProfile?.date_of_birth ?? ''}
        email={loggedUserProfile?.email ?? ''}
        region={loggedUserProfile?.country_code ?? ''}
        playerId={loggedUserProfile?.player_id}
      />
      {loggedUserProfile?.country_code.toLowerCase() !== 'jp' && <BaseContentBox customWidth={700} autoSizeBox={true} title={<div className='title'>{t('profile_overview_title', 'Profile Overview')}</div>}>
        {showSuccess
          ? <Callout variant='success'>{t('profile_updated', 'Profile successfully updated!')}</Callout>
          : undefined}
        <Form
          onSubmit={onSubmit}
          key={selectedAccount?.guid}
          render={
            ({ dirty, handleSubmit, submitting, hasSubmitErrors, hasValidationErrors, modifiedSinceLastSubmit, touched }) => (
              <form className='change-password-form' onSubmit={handleSubmit}>
                <div>
                  <p className='form-wrapper' id='first-name-label'>
                    {t('first_name', 'First Name')}
                  </p>
                </div>
                <Field name="first_name">
                  {({ input }) => (
                    <TextField
                      type='text'
                      placeholder={loggedUserProfile?.first_name}
                      data-testid='first-name-test'
                      disabled={!!loggedUserProfile?.first_name}
                      aria-labelledby='first-name-label'
                      {...input}
                    />
                  )}
                </Field>
                <div>
                  <p className='form-wrapper' id='last-name-label'>
                    {t('last_name', 'Last Name')}
                  </p>
                </div>
                <Field name="last_name">
                  {({ input }) => (
                    <TextField
                      type='text'
                      placeholder={loggedUserProfile?.last_name}
                      data-testid='last-name-test'
                      disabled={!!loggedUserProfile?.last_name}
                      aria-labelledby='last-name-label'
                      {...input}
                    />
                  )}
                </Field>

                <div>
                  <p className='form-wrapper' id='username-label'>
                    {t('username', 'Username')}
                  </p>
                </div>
                <Field name="username">
                  {({ input }) => (
                    <TextField
                      type='text'
                      placeholder={loggedUserProfile?.username}
                      data-testid='username-test'
                      disabled={true}
                      aria-labelledby='username-label'
                      {...input}
                    />
                  )}
                </Field>

                <div>
                  <p className='form-wrapper' id='screen-name-label'>
                    {t('screen_name', 'Screen Name')}
                  </p>
                </div>
                <Field name="screen_name" validate={validateScreennameIfPresent()}>
                  {({ input, meta }) => (
                    <TextField
                      type='text'
                      placeholder={loggedUserProfile?.screen_name}
                      data-testid='screen_name-test'
                      disabled={!!loggedUserProfile?.screen_name}
                      errorText={meta.touched && (meta.error || (!modifiedSinceLastSubmit && meta.submitError))}
                      aria-labelledby='screen-name-label'
                      {...input}
                    />
                  )}
                </Field>

                <Field name="public_profile" type='checkbox' initialValue={loggedUserProfile?.public_profile} >
                  {({ input }) => (
                    <div className='public-profile-wrapper'>
                      <div className='public-profile-description'>
                        <h4>{t('public_profile_title', 'Display your Pokémon Trainer Club profile publicly?')}</h4>
                        <p>{t('public_profile_description', 'This includes content such as your Trainer image, screen name, and game statistics. Personal information such as your real name is always kept private.')}</p>
                      </div>
                      <div className='public-profile-toggle'>
                        <ToggleSwitch
                          id="public-profile-toggle-switch"
                          data-testid='public-profile-test'
                          value={input.value ? 'true' : 'false'}
                          checked={input.checked}
                          name={input.name}
                          onChange={input.onChange}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          controlled={true}
                        />
                      </div>
                    </div>
                  )}
                </Field>
                  {
                    !isChildView
                      ? <>
                        <p>
                          {t('email_updates_section', 'I would like to receive email updates from The Pokémon Company International regarding:')}
                        </p>
                        <Field
                          name="email_opt_in"
                          type="checkbox"
                          initialValue={loggedUserProfile?.email_opt_in}
                        >
                          {({ input }) => (
                            <Checkbox
                              data-testid='email_opt_in-test'
                              checked={input.checked}
                              name={input.name}
                              onChange={input.onChange}
                              onFocus={input.onFocus}
                              onBlur={input.onBlur}
                              id="email_opt_in"
                              label={t('email_updates_general', 'News and information about Pokémon')}
                            />
                          )}
                        </Field>
                        <Field
                          name="email_opt_in_pcenter"
                          type="checkbox"
                          initialValue={loggedUserProfile?.email_opt_in_pcenter}
                        >
                          {({ input }) => (
                            <Checkbox
                              data-testid='email_opt_in_pcenter-test'
                              checked={input.checked}
                              name={input.name}
                              onChange={input.onChange}
                              onFocus={input.onFocus}
                              onBlur={input.onBlur}
                              id="email_opt_in_pcenter"
                              label={t('email_updates_pcenter', 'News and updates about Pokémon Center (our official online shop)')}
                            />
                          )}
                        </Field>
                      </>
                      : undefined
                  }
                  <Button
                    className="save-button"
                    data-testid='save'
                    type={'submit'}
                    buttonStatus={'primary'}
                    text={t('save_button', 'Save')}
                    disabled={submitting || hasValidationErrors || (hasSubmitErrors && !modifiedSinceLastSubmit)}
                  />
                </form>

            )}
          />
        </BaseContentBox>}
      </LoadingContainer>
  )
}
