import { getDays } from '../pages/child_acc_creation/utils/dob_utils'

export interface DatePartsObject {
  year?: string;
  month?: string;
  day?: string;
}

const Validators = {
  validateBirthDate: (t: (key: string, fallback: string) => string) => {
    return (datePartsObject: DatePartsObject) => {
      if (
        !datePartsObject.year ||
        !datePartsObject.month ||
        !datePartsObject.day
      ) {
        return t('incomplete_date', 'The date you entered is incomplete.')
      }
      if (
        Number(datePartsObject.day) >
        getDays(datePartsObject.month, datePartsObject.year, true)
      ) {
        return t('invalid_date', 'The date you entered does not exist.')
      }
      if (new Date(Number(datePartsObject.year), Number(datePartsObject.month) - 1, Number(datePartsObject.day)) > new Date()) {
        return t('future_dob', 'The date of birth you entered is in the future.')
      }
    }
  },

  validateCode: (x: string) => /^[0-9]{6}$/.test(x)
}

export default Validators
