import { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Form, Field, useField, useForm } from 'react-final-form'
import { Button } from '@pokemon/design-v2.button'
import { DatePicker, DatePickerSelection } from '@pokemon/design.ui.input.date-picker'
import { Modal } from '@pokemon/design.ui.containers.modal'
import { ModalContext } from '@pokemon/design.ui.containers.base-modal'
import Validators from '../../utils/formValidators'
import { months, pokemonDOBFormatter } from './utils/dob_utils'
import { screens } from './child_acc_creation'
import { checkField } from '../../utils/tamApi'
import { AuthContextValue, AuthContext } from '../../utils/auth/context'
import { LoadingContext } from '@pokemon/design.ui.loading-spinner'

interface ChildDOBProps {
  saveDoB: (dateOfBirth:string, screen:string) => void
  changeScreen?: (screen:string) => void
  setError: (text: string) => void
}

interface SubmitValues {
  dateOfBirth : DatePickerSelection
}

export function ChildDOB (props: ChildDOBProps) {
  const { saveDoB, changeScreen } = props
  const { t } = useTranslation()
  const { setLoading } = useContext(LoadingContext)
  const { openModal } = useContext(ModalContext)
  const initialDate: DatePickerSelection = {}
  const { accessToken } = useContext<AuthContextValue>(AuthContext)

  const onSubmit = async (values : SubmitValues) => {
    if (values.dateOfBirth.year && values.dateOfBirth.month && values.dateOfBirth.day && accessToken) {
      const birthDate = new Date(parseInt(values.dateOfBirth.year), parseInt(values.dateOfBirth.month) - 1, parseInt(values.dateOfBirth.day))
      // let's process our date of birth from a JS object to a string like YYYY-MM-DD
      const birthDateString: string = pokemonDOBFormatter(birthDate)
      setLoading(true)
      try {
        const ageCheck = await checkField(accessToken.token, { date_of_birth: birthDateString })
        const ageAnswer = ageCheck.data.date_of_birth
        if (ageAnswer && changeScreen) {
          changeScreen(screens.CHILD_ADULT)
        } else {
          saveDoB(birthDateString, screens.ENTER_EMAIL)
        }
      } catch (e) {
        console.log(e)
        props.setError('Unexpected error in setting date of birth')
      }
      setLoading(false)
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, valid }) => (
        <form className='change-display-form' onSubmit={handleSubmit}>
          <div className='centered-text'>
            <h1>{t('creation_header', 'What is Your Child’s Birthdate')}</h1>
            <p className="medium_size_text">{t('enter_starting_info', 'Please enter accurate information or you may be unable to recover your child’s account should you forget your username or password.')}</p>
          </div>
          <Field
            name="dateOfBirth"
            initialValue={initialDate}
            validate={Validators.validateBirthDate(t)}
            render={({ input, meta }) => (
              <>
                <DatePicker
                  monthLabel={t('month', 'Month')}
                  dayLabel={t('day', 'Day')}
                  yearLabel={t('year', 'Year')}
                  months={months(t)}
                  onChange={input.onChange}
                  onFocus={input.onFocus}
                  onBlur={input.onBlur}
                  value={input.value}
                />
                {meta.error && meta.touched && <p className='input-error'>{meta.error}</p>}
              </>
            )}
          />
          <Button
            text={t('continue_button', 'Continue')}
            id="dOBSubmit"
            data-testid="dOBSubmit"
            disabled={submitting || !valid}
            style={{ marginTop: 30 }}
            onClick={() => {
              openModal('dobConfirm')
            }} />
          <DoBModal />
        </form>
      )}
    />
  )
}

const DoBModal = () => {
  const { input: { value: dateOfBirth } } = useField('dateOfBirth')
  const { submit } = useForm()

  const { closeModal } = useContext(ModalContext)
  const { t } = useTranslation()

  return <Modal
    name="dobConfirm"
    primaryOnClick={() => {
      submit()
      closeModal('dobConfirm')
    }}
    title={t('dob_confirm_title', 'Everything Correct?')}
    primaryText={t('dob_confirm', 'I Am Sure')}
    closeText={t('dob_cancel', 'Go Back')}
    contentBoxProps={{
      Logo: null
    }}
  >
    <div className='modal-contents'>
      <Trans
        t={t}
        i18nKey='dob_confirm_details'
        defaults='Please double-check that you have accurately entered your child’s <strong>birthdate</strong> as you may be unable to recover your account should you forget your username or password.'
        components={{ strong: <strong /> }}
      />
      <div className='grey-callout-box' style={{ marginTop: '10px', marginBottom: '30px' }}>
        <p>
          <Trans
            t={t}
            i18nKey='date_format'
            defaults='{{month}} {{day}}, {{year}}'
            values={{
              year: dateOfBirth.year,
              month: months(t)[dateOfBirth.month - 1],
              day: dateOfBirth.day
            }}
          />
        </p>
      </div>
    </div>
  </Modal>
}
